var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "a-card",
        {
          attrs: {
            title: _vm.$t("common.create-text", {
              text: _vm.$t("lbl_asset_transfer")
            })
          }
        },
        [
          _c(
            "a-form-model",
            {
              ref: "formRef",
              attrs: {
                model: _vm.formData,
                rules: _vm.formRules,
                "label-col": { span: 8 },
                "wrapper-col": { span: 14 },
                layout: "vertical",
                "label-align": "left",
                "data-testid": "create-asset-transfer-form"
              }
            },
            [
              _c(
                "a-row",
                { attrs: { gutter: [16, 16] } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: _vm.$t("lbl_from"), prop: "from" } },
                        [
                          _c("SelectRack", {
                            attrs: { "label-in-value": "", valueAs: "id" },
                            model: {
                              value: _vm.formData.from,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "from", $$v)
                              },
                              expression: "formData.from"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: _vm.$t("lbl_to"), prop: "to" } },
                        [
                          _c("SelectRack", {
                            attrs: { "label-in-value": "", valueAs: "id" },
                            model: {
                              value: _vm.formData.to,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "to", $$v)
                              },
                              expression: "formData.to"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: _vm.$t("lbl_date"), prop: "date" } },
                        [
                          _c("a-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              format: _vm.DEFAULT_DATE_FORMAT,
                              "disabled-date": _vm.beforeToday
                            },
                            model: {
                              value: _vm.formData.date,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "date", $$v)
                              },
                              expression: "formData.date"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_expedition"),
                            prop: "expedition"
                          }
                        },
                        [
                          _c("SelectExpedition", {
                            on: { "update:meta": _vm.handleExpeditionChange },
                            model: {
                              value: _vm.formData.expedition,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "expedition", $$v)
                              },
                              expression: "formData.expedition"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_expedition_address"),
                            prop: "expeditionAddress"
                          }
                        },
                        [
                          _c("a-select", {
                            attrs: {
                              placeholder: _vm.$t("common.search-text", {
                                text: _vm.$t("lbl_expedition_address")
                              }),
                              options: _vm.expeditionAddressOptions
                            },
                            model: {
                              value: _vm.formData.expeditionAddress,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "expeditionAddress", $$v)
                              },
                              expression: "formData.expeditionAddress"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_attachment"),
                            help:
                              "Maximum file size is 5MB and accepted file extension is PDF, JPG, PNG, XLSX",
                            prop: "attachment"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "extra",
                              fn: function() {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("attachment.allow-common-type") +
                                          ". " +
                                          _vm.$t("attachment.allow-size")
                                      )
                                    )
                                  ])
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c(
                            "a-upload",
                            {
                              attrs: {
                                "before-upload": _vm.handleBeforeUpload,
                                accept: "image/*,.pdf,.xlsx",
                                "custom-request": _vm.handleUploadAttachment,
                                "file-list": _vm.attachments,
                                remove: _vm.handleRemoveAttachment
                              }
                            },
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: {
                                    icon: "upload",
                                    "data-testid":
                                      "unit-receipt-monitoring-receipt-modal-attachment",
                                    loading: _vm.loading.uploadAttachment
                                  }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("lbl_upload")) + " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_truck_number"),
                            prop: "truckNumber"
                          }
                        },
                        [
                          _c("a-input", {
                            attrs: { placeholder: _vm.$t("lbl_truck_number") },
                            model: {
                              value: _vm.formData.truckNumber,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "truckNumber", $$v)
                              },
                              expression: "formData.truckNumber"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_driver_name_and_phone_number"),
                            prop: "driverNameAndPhoneNumber"
                          }
                        },
                        [
                          _c("a-input", {
                            attrs: {
                              placeholder: _vm.$t(
                                "lbl_driver_name_and_phone_number"
                              )
                            },
                            model: {
                              value: _vm.formData.driverNameAndPhoneNumber,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.formData,
                                  "driverNameAndPhoneNumber",
                                  $$v
                                )
                              },
                              expression: "formData.driverNameAndPhoneNumber"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: { label: _vm.$t("lbl_notes"), prop: "notes" }
                        },
                        [
                          _c("a-textarea", {
                            attrs: {
                              placeholder: _vm.$t("lbl_notes"),
                              "allow-clear": ""
                            },
                            model: {
                              value: _vm.formData.notes,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "notes", $$v)
                              },
                              expression: "formData.notes"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c("a-table", {
                        attrs: {
                          size: "small",
                          "row-key": "no",
                          columns: _vm.columns,
                          "data-source": _vm.formData.assetTransferLines,
                          pagination: {
                            showTotal: function(total) {
                              return _vm.$t("lbl_total_items", { total: total })
                            },
                            showSizeChanger: true
                          },
                          scroll: { x: 1000 },
                          "row-class-name": function(_record, index) {
                            return index % 2 ? "bg-white" : "bg-gray-light"
                          },
                          "row-selection": {
                            selectedRowKeys: _vm.selectedRowKeys,
                            onChange: _vm.handleSelectedTableRowChange
                          },
                          "data-testid": "create-asset-transfer-table"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "title",
                            fn: function() {
                              return [
                                _c(
                                  "a-space",
                                  [
                                    _c(
                                      "a-button",
                                      {
                                        attrs: { type: "primary" },
                                        on: { click: _vm.handleAddRow }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("lbl_add_row")) +
                                            " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "a-button",
                                      {
                                        attrs: { type: "danger" },
                                        on: { click: _vm.handleDeleteRow }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("lbl_delete_row")) +
                                            " "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "unitCode",
                            fn: function(_, record) {
                              return [
                                _c("a-select", {
                                  attrs: {
                                    "allow-clear": "",
                                    "show-search": "",
                                    "label-in-value": "",
                                    "filter-option": false,
                                    loading:
                                      _vm.loading.unitCode ||
                                      record.loadingUnitCodeOptions,
                                    options:
                                      record.unitCodeOptions.length > 0
                                        ? record.unitCodeOptions
                                        : _vm.unitCodeOptions
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.handleUnitCodeChange(
                                        $event,
                                        record
                                      )
                                    },
                                    search: function($event) {
                                      return _vm.handleSearch($event, record)
                                    }
                                  },
                                  model: {
                                    value: record.unitCode,
                                    callback: function($$v) {
                                      _vm.$set(record, "unitCode", $$v)
                                    },
                                    expression: "record.unitCode"
                                  }
                                })
                              ]
                            }
                          },
                          {
                            key: "listAccessories",
                            fn: function(_, record) {
                              return [
                                _c("a-select", {
                                  attrs: {
                                    "allow-clear": "",
                                    "show-search": "",
                                    mode: "multiple",
                                    options: _vm.accessoryOptions
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "dropdownRender",
                                        fn: function(menu) {
                                          return _c(
                                            "div",
                                            {},
                                            [
                                              _c("ListAccessories", {
                                                attrs: { items: menu }
                                              }),
                                              _c("a-divider", {
                                                staticStyle: { margin: "4px 0" }
                                              }),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    padding: "8px",
                                                    cursor: "pointer"
                                                  },
                                                  attrs: { icon: "plus" },
                                                  on: {
                                                    mousedown: function(e) {
                                                      return e.preventDefault()
                                                    },
                                                    click: function($event) {
                                                      return _vm.modal.open()
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("a-icon", {
                                                    attrs: { type: "plus" }
                                                  }),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "common.add-text",
                                                          {
                                                            text: _vm.$t(
                                                              "lbl_new"
                                                            )
                                                          }
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  ),
                                  model: {
                                    value: record.listAccessories,
                                    callback: function($$v) {
                                      _vm.$set(record, "listAccessories", $$v)
                                    },
                                    expression: "record.listAccessories"
                                  }
                                })
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 24, align: "end" } },
                    [
                      _c(
                        "a-space",
                        [
                          _c(
                            "a-popconfirm",
                            {
                              attrs: {
                                title: _vm.$t("pop_confirmation"),
                                "ok-text": _vm.$t("lbl_yes"),
                                "cancel-text": _vm.$t("lbl_no")
                              },
                              on: { confirm: _vm.handleBack }
                            },
                            [
                              _c("a-button", [
                                _vm._v(" " + _vm._s(_vm.$t("lbl_back")) + " ")
                              ])
                            ],
                            1
                          ),
                          _vm.$can("create", "asset-transfer")
                            ? _c(
                                "a-button",
                                {
                                  attrs: { type: "primary" },
                                  on: { click: _vm.handleSubmit }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("lbl_submit")) + " "
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("CreateAccessoryModal", {
        attrs: {
          visible: _vm.modal.visible,
          "data-testid": "create-asset-transfer-modal"
        },
        on: {
          close: function($event) {
            return _vm.modal.close()
          },
          success: function($event) {
            return _vm.fetchAccessories()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }