














import {
  useFindMasterType,
  useMapMasterTypeToOption,
} from "@/hooks/master-type";
import { Option } from "@/models/class/option.class";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class SelectAmortizationStatus extends Vue {
  @Prop({ required: false, type: String, default: undefined })
  value!: string | undefined;

  @Prop({ required: false, type: Boolean, default: false })
  disabled!: boolean;

  options: Option[] = [];
  loading = false;

  created(): void {
    this.fetchData();
  }

  emits(e: string): void {
    this.$emit("input", e);
    this.$emit("change", e);
  }

  fetchData(): void {
    this.loading = true;
    useFindMasterType("AMORTIZATION_STATUS")
      .then(res => {
        this.options = useMapMasterTypeToOption(res, true);
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
