





















import { SearchBuilder } from "@/builder";
import { debounceProcess } from "@/helpers/debounce";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { ListContactDataDto } from "@/models/interface/contact-data";
import { contactServices } from "@/services/contact.service";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class SelectExpedition extends Vue {
  @Prop({ type: String, default: undefined })
  value!: string | undefined;

  @Prop({ required: false, type: Boolean, default: false })
  disabled!: boolean;

  options: Option<ListContactDataDto>[] = [];

  loading = false;

  onChange(e: string): void {
    if (!e) {
      this.onSearch();
    }

    this.$emit("input", e);
    this.$emit("change", e);
    this.$emit("update:meta", this.findOption(e));
  }

  onSearch(search?: string): void {
    const builder: SearchBuilder = new SearchBuilder();

    const query = builder
      .push(["supplierData.supplierType", "Ekspedisi"])
      .and()
      .push(["supplier", "true"])
      .and()
      .push(["active", "true"]);

    if (search) {
      query
        .and()
        .push(["firstName", search], { like: "both" })
        .or()
        .push(["lastName", search], { like: "both" });
    }

    const params: RequestQueryParams = new RequestQueryParams();
    params.search = query.build();

    this.loading = true;
    contactServices
      .getListContactData(params)
      .then(res => {
        this.options = res.data.map(expedition => ({
          key: expedition.id,
          label: expedition.fullName,
          value: expedition.id,
          meta: expedition,
        }));
      })
      .finally(() => (this.loading = false));
  }

  findOption(id: string): ListContactDataDto | undefined {
    return this.options.find(e => id === e.key)?.meta;
  }

  created(): void {
    this.onSearch = debounceProcess(this.onSearch);

    this.onSearch();
  }
}
