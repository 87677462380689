var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        title: _vm.$t("common.add-text", { text: _vm.$t("lbl_new") }),
        visible: _vm.visible
      },
      on: { cancel: _vm.handleClose },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "div",
                { staticClass: "text-right mt-3" },
                [
                  _c(
                    "a-space",
                    [
                      _c("a-button", { on: { click: _vm.handleClose } }, [
                        _vm._v(" " + _vm._s(_vm.$t("lbl_close")) + " ")
                      ]),
                      _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            loading: _vm.loading.create
                          },
                          on: { click: _vm.createAccessory }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_save")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "a-form-model",
        {
          ref: "formModel",
          attrs: { model: _vm.formData, rules: _vm.formRules }
        },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.$t("lbl_accesory_name"), prop: "name" } },
            [
              _c("a-input", {
                attrs: { placeholder: _vm.$t("lbl_accesory_name") },
                model: {
                  value: _vm.formData.name,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "name", $$v)
                  },
                  expression: "formData.name"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }