


































import MNotification from "@/mixins/MNotification.vue";
import { salesOrderServices } from "@/services/salesorder.service";
import { FormModel } from "ant-design-vue";
import { Component, Mixins, Prop, Ref } from "vue-property-decorator";

interface FormData {
  name: string;
}

@Component({})
export default class CreateAccessoryModal extends Mixins(MNotification) {
  @Prop({ type: Boolean, default: false, required: false })
  visible!: boolean;

  @Ref("formModel")
  formModel!: FormModel;

  formData: FormData = {
    name: "",
  };

  formRules = {
    name: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
  };

  loading = {
    create: false,
  };

  handleClose(): void {
    this.$emit("close");
  }

  createAccessory(): void {
    this.formModel.validate((valid: boolean) => {
      if (!valid) {
        return;
      }

      this.loading.create = true;
      salesOrderServices
        .createAccessories({
          name: this.formData.name,
          description: this.formData.name,
        })
        .then(() => {
          this.showNotifSuccess("notif_create_success");
          this.formData.name = "";

          this.handleClose();
          this.$emit("success");
        })
        .finally(() => (this.loading.create = false));
    });
  }
}
