var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a-select", {
    attrs: {
      value: _vm.value,
      loading: _vm.loading,
      "allow-clear": "",
      "show-search": "",
      "option-filter-prop": "children",
      placeholder: _vm.$t("common.select-text", { text: _vm.$t("lbl_status") }),
      disabled: _vm.disabled,
      options: _vm.options
    },
    on: { change: _vm.emits }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }